import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Heading from '../components/heading'
import Content from '../components/content'
import Gif from '../components/gif'
import * as R from 'ramda'

import './style.scss'

const IndexPage = ({ data }) => {
  const post = R.head(R.path(['post', 'edges'], data)).node
  return (
    <Layout
      bgColor={post.acf.bgcolor}
      heroImg={post.acf.hero_kuva}
      current={post.acf.order}
      hero_sticker={post.acf.hero_sticker}
    >
      <SEO
        title="Etusivu"
        description="Eläkepätäkkä kertoo eläkeasioista nuorille"
        image={post.acf.hero_kuva}
      />

      <div className="frontFlexContainer">
        <div className="frontLeft">
          <Heading heading={post.title} />
          <Content content={post.content} />
        </div>
        <div className="frontRight">
          <Gif gif={post.acf.inline_video} videoStill={post.acf.video_still} />
        </div>
      </div>

      <div className="templateGifContainer">
        <Gif gif={post.acf.gif} videoStill={post.acf.gif_still} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query frontpageQuery {
    post: allWordpressWpCampaings(filter: { slug: { eq: "etusivu" } }) {
      edges {
        node {
          title
          content
          slug
          acf {
            hero_kuva
            order
            inline_video
            gif
            gif_still
            hero_sticker
            bgcolor
            video_still
          }
        }
      }
    }
  }
`
